import { get, find } from 'lodash';

export default {
    name: 'customCustomerDetails',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'customerDetails';
        const route = app.routes.getItem(routeName);

        if (route) {
            const routeProps = route.props;

            const customGridConfig = (data, customer = {}) => {
                const customerCustomValues = get(customer, 'customValues', []);
                const customerFullyQualifiedName = get(
                    find(customerCustomValues, ['custom_field_id', 'fully_qualified_name']),
                    'value',
                    '',
                );

                if (!customerFullyQualifiedName) {
                    return data;
                }

                return {
                    ...data,
                    filtering: {
                        customer_fully_qualified_name: {
                            filterType: 'equal',
                            value: customerFullyQualifiedName,
                        },
                    },
                };
            };

            route.props = (state) => ({
                ...routeProps(state),
                customOrdersGridConfig: customGridConfig,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
