import { useState, useRef } from 'react';
import { get, set, find, findIndex } from 'lodash';
import useCustomerUpdate from './hooks/useCustomerUpdate';

const useClientField = ({ dictionaries, onChangeField, errorNotification, successNotification }) => {
    const formRef = useRef();
    const [modalData, setModalData] = useState({
        isOpen: false,
        id: '',
        email: '',
    });

    const handleSubmit = async (e, values) => {
        const { email } = values;
        await updateCustomer({ customerId: modalData.id, email });
    };

    const handleConfirm = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    const handleClose = () => {
        setModalData((state) => ({
            ...state,
            isOpen: false,
            email: '',
        }));
    };

    const handleCustomerUpdateSuccess = ({ customerId, email }) => {
        const customer = find(get(dictionaries, 'customers', []), ['value', customerId]);

        set(customer, 'email', email);

        const customerCustomValues = get(customer, 'customValues', []);
        const emailIsConfiremdIndex = findIndex(customerCustomValues, ['custom_field_id', 'email_is_confirmed']);
        if (emailIsConfiremdIndex >= 0) {
            customerCustomValues[emailIsConfiremdIndex] = {
                ...customerCustomValues[emailIsConfiremdIndex],
                value: 1,
            };

            set(customer, 'customValues', [...customerCustomValues]);
        } else {
            set(customer, 'customValues', [
                ...customerCustomValues,
                {
                    custom_field_id: 'email_is_confirmed',
                    value: 1,
                },
            ]);
        }

        onChangeField({ name: 'customer_id', value: customerId });
        handleClose();
    };

    const [{ isSubmitting }, { updateCustomer }] = useCustomerUpdate({
        errorNotification,
        successNotification,
        onSuccess: handleCustomerUpdateSuccess,
    });

    const handleChange = (customerId) => {
        const customer = find(get(dictionaries, 'customers', []), ['value', customerId]);
        const customerCustomValues = get(customer, 'customValues', []);
        const customerEmailIsConfirmed = get(
            find(customerCustomValues, ['custom_field_id', 'email_is_confirmed']),
            'value',
            0,
        );
        const customerEmail = get(customer, 'email', '');

        if (!customer || customerEmailIsConfirmed) {
            onChangeField({ name: 'customer_id', value: customerId });
        } else {
            setModalData((state) => ({
                ...state,
                isOpen: true,
                id: customerId,
                email: customerEmail,
            }));
        }
    };

    return [
        { formRef, ...modalData, isSubmitting },
        { handleChange, handleSubmit, handleConfirm },
    ];
};

export default useClientField;
